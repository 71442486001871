import '@shopify/polaris/dist/styles.css';
import nlTranslations from '@shopify/polaris/locales/nl.json';
import './App.scss';
import {Provider, Loading} from '@shopify/app-bridge-react';
import {AppProvider, Page, Card, DataTable, DatePicker, Layout, Stack, Button, Link, Select, Icon, Checkbox, Popover, FormLayout } from '@shopify/polaris';
import { PrintMajor } from '@shopify/polaris-icons';
import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/nl';

import createApp from '@shopify/app-bridge';
import {Redirect} from '@shopify/app-bridge/actions';

const app = createApp({
  apiKey: 'cbcae0b92a979f88e51bb4b4a4f0a3b0',
  shopOrigin: 'bastiano-bonheiden.myshopify.com'
});
const redirect = Redirect.create(app);

function App() {
  const startdate = moment().startOf('month').subtract(1,'month');
  const [{month,year}, setDate] = useState({month:startdate.month(), year:startdate.year()});
  const [selectedDates, setSelectedDates] = useState({
    //start: moment().subtract(6, 'days').startOf('day').toDate(),
    start: moment().startOf('day').toDate(),
    end: moment().startOf('day').toDate(),
  });
  const [showCalendar, setShowCalendar] = useState(false);
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [products, setProducts] = useState([]);
  const [totals, setTotals] = useState(['','','']);
  const [{index,direction}, setSort] = useState({index:0,direction:'ascending'});
  const [filter, setFilter] = useState('');

  const [popoverActive, setPopoverActive] = useState(false);

  const [ordersShown, setOrdersShown] = useState(true);
  const handleOrdersShown = useCallback((newChecked) => setOrdersShown(newChecked), []);

  const [amountsShown, setAmountsShown] = useState(true);
  const [totalShown, setTotalShown] = useState(true);
  const handleAmountsShown = useCallback((newChecked) => setAmountsShown(newChecked), []);
  const handleTotalShown = useCallback((newChecked) => setTotalShown(newChecked), []);

  const config = {
    apiKey:'cbcae0b92a979f88e51bb4b4a4f0a3b0',
    shopOrigin: 'bastiano-bonheiden.myshopify.com'
  };
  const api_url = "https://shopify-agpo.thomascolliers.com/";
  const loadingComponent = loading ? <Loading /> : false;

  const loadData = () => {
    setLoading(true);
    setRows([]);
    let url = `${api_url}api/orders`;
    fetch(url).then(response => response.json()).then(data => {
      if(data.status && data.status === "ok"){
        let products = data.products;
        for(var i = 0; i < products.length; i++){
          for(var e = 0; e < products[i].orders.length; e++){
            products[i].orders[e].date_moment = moment(products[i].orders[e].date,"DD/MM/YYYY");
          }
        }
        setProducts(products);
      }
      setLoading(false);
    });
  }

  useEffect(() => {
    loadData();
  },[]);

  useEffect(() => {
    var newrows = [];
    var newtotals = [0,0,0];

    let start = moment(selectedDates.start);
    let end = moment(selectedDates.end).add(1,'day');

    // filter orders by date and filter if needed
    let filtered = products.map((item) => {  
      // filter out with date
      var orders = item.orders.filter((item) => {
        return item.date_moment.isBetween(start,end,undefined,'[)');
      });
      // filter out orders with category
      if(filter.length > 0){
        orders = orders.filter((item) => {
          return item.category === filter;
        });        
      }else{
        orders = orders.filter((item) => {
          return item.category.length > 0;
        });
      }
      item.orders_filtered = orders;
      return item;
    // filter out products without orders
    }).filter((item) => {
      return item.orders_filtered.length > 0;
    }).map((item) => {
      item.amount_total = 0;

      item.amount_delivery = 0;
      item.orders_delivery = [];
      item.orders_filtered.map((order) => {
        if(order.category === "THUISLEVERING"){
          item.amount_total += order.amount;
          item.amount_delivery += order.amount;
          item.orders_delivery.push(order);
        }
        return null;
      });

      item.amount_pickup = 0;
      item.orders_pickup = [];
      item.orders_filtered.map((order) => {
        if(order.category === "OPHALING"){
          item.amount_total += order.amount;
          item.amount_pickup += order.amount;
          item.orders_pickup.push(order);
        }
        return null;
      });
      return item;
    });

    // sort the filtered result by the right column
    filtered = sortrows(index,direction,filtered);

    // create table elements
    for(const product of filtered){
      var orders_delivery = [];
      for(const order of product.orders_delivery){
        orders_delivery.push(<span key={order.order_id}><Link url={`https://bastiano-bonheiden.myshopify.com/admin/orders/${order.order_id}`} onClick={(e) => {
          e.preventDefault();
          redirect.dispatch(Redirect.Action.ADMIN_PATH, '/orders/'+order.order_id);
          return false;
        }}>{order.order_number}</Link> ({order.amount})</span>);
      }
      var orders_pickup = [];
      for(const order of product.orders_pickup){
        orders_pickup.push(<span key={order.order_id}><Link url={`https://bastiano-bonheiden.myshopify.com/admin/orders/${order.order_id}`} onClick={(e) => {
          e.preventDefault();
          redirect.dispatch(Redirect.Action.ADMIN_PATH, '/orders/'+order.order_id);
          return false;
        }}>{order.order_number}</Link> ({order.amount})</span>);
      }

      newrows.push([
        <Link url={`https://bastiano-bonheiden.myshopify.com/admin/products/${product.product_id}`} onClick={(e) => {
          e.preventDefault();
          redirect.dispatch(Redirect.Action.ADMIN_PATH, '/products/'+product.product_id);
          return false;
        }}>{product.title}</Link>,
        product.amount_total,
        product.amount_delivery,
        <Stack>{orders_delivery}</Stack>,
        product.amount_pickup,
        <Stack>{orders_pickup}</Stack>,
      ]);
      newtotals[0] += product.amount_total;
      newtotals[1] += product.amount_delivery;
      newtotals[2] += product.amount_pickup;
    }
    setRows(newrows);
    setTotals(newtotals);
  },[selectedDates,products,filter,index,direction]);

  const handleMonthChange = useCallback(
    (month, year) => {
      setDate({month,year});
      /*if(moment(`${year}${month+1}`,"YYYYMM").isAfter(startdate)){
        setDate({month:startdate.month(),year:startdate.year()});
      }else{
        setDate({month,year});
      }*/
    },
    []
  );

  const sortrows = (index, direction, rows) => {
    if(index === 0){
      return [...rows].sort((row_a, row_b) => (direction === "descending" ? -1 : 1)*row_a.title.localeCompare(row_b.title));
    }else if(index === 1){
      return [...rows].sort((row_a, row_b) => (direction === "descending" ? -1 : 1)*(row_a.amount_total - row_b.amount_total));
    }else if(index === 2){
      return [...rows].sort((row_a, row_b) => (direction === "descending" ? -1 : 1)*(row_a.amount_delivery - row_b.amount_delivery));
    }else if(index === 4){
      return [...rows].sort((row_a, row_b) => (direction === "descending" ? -1 : 1)*(row_a.amount_pickup - row_b.amount_pickup));
    }
  }

  const handleSort = (index, direction) => {
    setSort({index,direction})
  }

  const handleSelectChange = (selected, id) => {
    setFilter(selected);
  }

  let tableClasses = [];
  if(filter.length){
    if(filter === "THUISLEVERING"){
      tableClasses.push("table-thuislevering");
    }else{
      tableClasses.push("table-ophaling");
    }
  }
  if(!ordersShown){
    tableClasses.push('hide-orders');
  }
  if(!amountsShown){
    tableClasses.push('hide-amounts');
  }
  if(!totalShown){
    tableClasses.push('hide-total')
  }

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  );
  const activator = (
    <Button onClick={togglePopoverActive} disclosure>
      Kolommen
    </Button>
  );
  
  return (
    <AppProvider i18n={nlTranslations}>
      <Provider config={config}>
        <div className="App">
          {loadingComponent}
          <Page fullWidth>
            <Layout>
              <Layout.Section>
                { showCalendar ?
                <Card sectioned={true} primaryFooterAction={{content: "Bijwerken",onAction:() => { setShowCalendar(false); }}}>
                  <DatePicker
                  month={month}
                  year={year}
                  onChange={(dates) => {
                    setSelectedDates(dates);
                  }}
                  onMonthChange={handleMonthChange}
                  selected={selectedDates}
                  multiMonth
                  allowRange
                  weekStartsOn={1}
                  />
                </Card> : 
                  <Card sectioned={true}>
                    <Stack alignment="center">
                      <div>Van <strong>{moment(selectedDates.start).format("D MMMM YYYY")}</strong> tot en met <strong>{moment(selectedDates.end).format("D MMMM YYYY")}</strong></div>
                      <div className="hideprint"><Button primary onClick={() => setShowCalendar(true)}>Aanpassen</Button></div>
                      <Select onChange={handleSelectChange} options={[
                        {label:"Alles",value:""},
                        {label:"Thuislevering",value:"THUISLEVERING"},
                        {label:"Ophaling",value:"OPHALING"},
                      ]} value={filter} />
                      <Popover
                        active={popoverActive}
                        activator={activator}
                        onClose={togglePopoverActive}
                        ariaHaspopup={false}
                        sectioned>
                          <FormLayout>
                            <Checkbox 
                              label="Totaal"
                              checked={totalShown}
                              onChange={handleTotalShown}
                            />
                            <Checkbox 
                              label="Aantal"
                              checked={amountsShown}
                              onChange={handleAmountsShown}
                            />
                            <Checkbox 
                              label="Order nummers"
                              checked={ordersShown}
                              onChange={handleOrdersShown}
                            />
                          </FormLayout>
                      </Popover>
                      <div className="hideprint"><Button onClick={() => {
                        setSelectedDates({
                          start:moment().startOf('day').toDate(),
                          end:moment().startOf('day').toDate(),
                        });
                      }}>Vandaag</Button></div>
                      <div className="hideprint"><Button  onClick={() => {
                        setSelectedDates({
                          start:moment().add(1,'days').startOf('day').toDate(),
                          end:moment().add(1,'days').startOf('day').toDate(),
                        });
                      }}>Morgen</Button></div>
                      <div className="hideprint"><Button onClick={() => {
                        setSelectedDates({
                          start:moment().subtract(1,'days').startOf('day').toDate(),
                          end:moment().subtract(1,'days').startOf('day').toDate(),
                        });
                      }}>Gisteren</Button></div>
                      <div className="hideprint"><Button onClick={() => {
                        setSelectedDates({
                          start:moment().subtract(2,'days').startOf('day').toDate(),
                          end:moment().subtract(2,'days').startOf('day').toDate(),
                        });
                      }}>Eergisteren</Button></div>
                      <div className="hideprint"><Button onClick={() => {
                        setSelectedDates({
                          start: moment().subtract(6, 'days').startOf('day').toDate(),
                          end: moment().endOf('day').toDate(),
                        });
                      }}>Laatste 7 dagen</Button></div>
                      <div className="hideprint"><Button onClick={() => {
                        setSelectedDates({
                          start: moment().subtract(1, 'month').startOf('day').toDate(),
                          end: moment().endOf('day').toDate(),
                        });
                      }}>Laatste maand</Button></div>
                      <div className="hideprint"><Button onClick={() => {
                        window.print();
                      }}><Icon source={PrintMajor} /></Button></div>
                    </Stack> 
                  </Card>
                }
              <Card>
                <div className={tableClasses.join(' ')}>
                  <DataTable
                    columnContentTypes={["text","numeric","numeric","text","numeric","text"]}
                    headings={["Product","# totaal","# levering","Orders levering","# ophaling","Orders ophaling"]}
                    rows={rows}
                    sortable={[true,true,true,false,true,false]}
                    defaultSortDirection="descending"
                    initialSortColumnIndex={0}
                    totals={["",totals[0],totals[1],"",totals[2],""]}
                    showTotalsInFooter={true}
                    onSort={handleSort}
                  />
                </div>
              </Card>
              </Layout.Section>
            </Layout>
          </Page>
        </div>
      </Provider>
    </AppProvider>
  );
}

export default App;
